/* eslint-disable @next/next/no-img-element */
import { formatCurrency } from '@/helpers/format';
import usePrefix from '@/hooks/usePrefix';
import { useMobxStores } from '@/stores';
import { Empty, Rate, Skeleton } from 'antd';
import { observer } from 'mobx-react-lite';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import ButtonStatus from './ButtonStatus';
import { isShowHighlights } from '@/helpers/highlights';
import { CDN1, CDN2 } from '@/helpers/api';
import { mapReviewStars } from '@/utils/tools';
import LowestPriceGuarantee from '@/components2/LowestPriceGuarantee';
import CardHighlights from './FacilityHighlights/CardHighlights';
import { isServer } from '@/utils/isServer';
import { setStorageCheckinCheckout } from '@/hooks/useLocalstorage';
import dayjs from 'dayjs';
import { getParkingAtPath, getUrlCodeOrSlug } from '@/utils/newparkingatroutes';

const Card = ({ configInfo, item, index, cardsRef, checkin, checkout }) => {
  const prefix = usePrefix();
  const router = useRouter();
  const { searchStore } = useMobxStores();

  useEffect(() => {
    sessionStorage.removeItem('checkin');
    sessionStorage.removeItem('checkout');
  }, []);

  return item.date_sold_out > 0 ? (
    <>
      {' '}
      <div
        ref={(el) => (cardsRef.current[index] = el)}
        key={item.facility_id}
        className={`relative w-[23rem] md:w-[50rem] h-auto mx-auto z-0 px-4 md:px-0 md:mb-8   ${
          item.selected ? 'shadow-2xl' : ''
        }`}
      >
        <div className="w-full relative shadow-sm border rounded-[0.675rem] overflow-hidden h-[13.7rem] mb-8 flex flex-row bg-white border-none">
          <div className="relative bg-gray-100 h-full w-[31%] md:w-[28%]">
            <Image
              src={CDN2() + '/staticmyapp/card-old-landing-3.webp'}
              className="z-0 object-cover w-full h-full"
              width={165}
              height={265}
              alt="image"
            />
          </div>
          <div className="ml-2 w-[62%] md:w-[70%]">
            <div className="w-full mb-2 md:mb-0">
              <div className="flex flex-col w-full col-span-2 md:px-0 self-center mx-auto">
                <div className="w-full rate-space">
                  <h2 className="text-black text-base font-bold text-left w-full truncate mt-2 pr-2">
                    {item.facility_lot}
                  </h2>
                  <Rate
                    allowHalf
                    disabled
                    value={mapReviewStars(item.facility_review_avg)}
                    className="text-left !text-base !mb-3"
                  />

                  {isShowHighlights(item.highlights) ? (
                    <CardHighlights
                      facility={item}
                      highlights={item.highlights}
                      size="small"
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="my-2">
              <div className="flex flex-row flex-wrap relative newLandingBtnStatus min-h-[57px] md:min-h-[45px]">
                <ButtonStatus
                  status={searchStore.facilityStatus(item)}
                  facility_url_code={item.facility_url_code}
                  minDays={item.facility_min_days}
                  maxDays={item.facility_max_days}
                  configInfo={configInfo}
                  isCardList={true}
                />

                {item.facilityParkingType == 3 ||
                item.facilityParkingType == 4 ? (
                  <div className="left-[118px] absolute top-0 flex flex-row items-center justify-center w-auto px-2 h-5 self-center py-1.5 rounded bg-gray-300 text-xs text-gray-600">
                    <div className="font-bold text-sm">
                      {formatCurrency({
                        value: item.facility_solid_selling_price
                          ? item.facility_solid_selling_price
                          : item.facility_afee,
                        locale: 'en-US',
                        currency: 'USD',
                      })}
                    </div>
                    <span className="mx-1"> / </span>
                    <div className="font-normal">
                      {item.facilityParkingType == 3
                        ? 'Sleep and Park'
                        : 'Monthly Park'}
                    </div>
                  </div>
                ) : (
                  <div className="left-[118px] absolute top-0 flex flex-row items-center justify-center w-auto px-2 h-5 self-center py-1.5 rounded bg-gray-300 text-xs text-gray-600">
                    <div className="font-bold text-sm">
                      {formatCurrency({
                        value: item.facility_selling_price,
                        locale: 'en-US',
                        currency: 'USD',
                      })}
                    </div>
                    <span className="mx-1"> / </span>
                    <div className="font-normal">day</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div
        key={item.facility_id}
        ref={(el) => (cardsRef.current[index] = el)}
        className={`relative w-[23rem] md:w-[50rem] h-auto mx-auto z-0 px-4 md:px-0 md:mb-8 ${
          item.selected ? 'shadow-2xl' : ''
        }`}
      >
        <Link
          href={`${prefix}/${getParkingAtPath(item)}/${getUrlCodeOrSlug(item)}`}
          key={item.facility_id + index}
          passHref
          legacyBehavior
        >
          <div className="cursor-pointer w-full h-auto  relative shadow-sm border rounded-[0.675rem] overflow-hidden mb-8 flex flex-row bg-white border-none">
            <div className="relative bg-white h-auto w-[31%] md:w-[28%]">
              {item.facility_url_code === 'ATLDISABLED' ? (
                <Image
                  src={`${CDN2()}/staticmyapp/peachy.png`}
                  className="z-0 object-none object-center w-full h-full"
                  alt="image"
                />
              ) : (
                <Image
                  src={`${CDN2()}/staticmyapp/card-old-landing-${
                    index % 2 === 0 ? '1' : '2'
                  }.webp`}
                  width={165}
                  height={265}
                  className="z-0 object-cover w-full h-full"
                  alt="image"
                />
              )}
              {item.best_seller === 1 && (
                <small className="flex flex-row items-center justify-center rounded-[0.188rem] h-5 w-[5.5rem] md:w-28 font-bold uppercase absolute z-10 left-1.5 md:left-10 top-[0.563rem] cursor-default bg-[#6B96D3] text-white">
                  <p className="text-[0.625rem] md:text-xs">Best Seller</p>
                </small>
              )}
              {item.best_review === 1 && (
                <small className="flex flex-row items-center justify-center rounded-[0.188rem] h-5 w-[5.5rem] md:w-28 font-bold uppercase absolute z-10 left-1.5 md:left-10 top-[0.563rem] cursor-default bg-white text-black">
                  <p className="text-[0.625rem] md:text-xs">Best Reviews</p>
                </small>
              )}
              {!item.facility_disable_lowest_price && item.best_price === 1 && (
                <small className="flex flex-row items-center justify-center rounded-[0.188rem] h-5 w-[5.5rem] md:w-28 font-bold uppercase absolute z-10 left-1.5 md:left-10 top-[0.563rem] cursor-default bg-primary text-white">
                  <p className="text-[0.625rem] md:text-xs">Lowest Price</p>
                </small>
              )}
            </div>
            <div className="ml-2 w-[67%] md:w-[70%]">
              <div className="w-full mb-2 md:mb-0">
                <div className="flex flex-col w-full col-span-2 md:px-0 self-center mx-auto">
                  <div className="w-full rate-space">
                    <h2 className="text-base font-extrabold text-left w-full truncate mt-4 mb-0.5 pr-2">
                      {item.facility_lot}
                    </h2>
                    <Rate
                      allowHalf
                      disabled
                      value={mapReviewStars(item.facility_review_avg)}
                      className="text-left !text-base mb-5"
                    />

                    {isShowHighlights(item.highlights) ? (
                      <CardHighlights
                        facility={item}
                        highlights={item.highlights}
                        size="small"
                      />
                    ) : (
                      <></>
                    )}

                    {item.incentive ? (
                      <p className="text-left flex flex-col gap-1 justify-center items-start text-xs ">
                        <span className="font-normal text-[#C30F0F]">
                          {item.incentive}
                        </span>
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="flex flex-row mt-4">
                    <div className="bg-[#069B27] mr-[28px] flex items-center justify-center w-auto px-2 h-5 rounded text-white text-[0.625rem]">
                      Online-only price
                    </div>
                    {item.facilityParkingType == 3 ||
                    item.facilityParkingType == 4 ? (
                      <div className="flex flex-col md:flex-row items-center justify-center w-auto h-14 md:h-5 px-2 py-1.5 rounded bg-gray-300 text-xs font-bold text-gray-600">
                        <div className="font-bold text-sm">
                          {formatCurrency({
                            value: item.facility_solid_selling_price
                              ? item.facility_solid_selling_price
                              : item.facility_afee,
                            locale: 'en-US',
                            currency: 'USD',
                          })}
                        </div>

                        <div className="font-normal">
                          <span className="mx-1"> / </span>
                          {item.facilityParkingType == 3
                            ? 'Sleep and Park'
                            : 'Monthly Park'}
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-row items-center justify-center w-[5.5rem] h-5 px-2 py-1.5 rounded bg-gray-300 text-xs font-bold text-gray-600">
                        <div className="font-bold text-sm ">
                          {formatCurrency({
                            value: item.facility_selling_price,
                            locale: 'en-US',
                            currency: 'USD',
                          })}
                        </div>
                        <span className="mx-1 md:mt-0.5"> / </span>
                        <div className="font-normal md:mt-0.5">day</div>
                      </div>
                    )}
                  </div>
                  {!item.facility_disable_lowest_price && (
                    <div className="mx-0 mt-2 md:mt-4">
                      <LowestPriceGuarantee isCardList={true} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

function CardList({ configInfo, searchAlternate, checkin, checkout }) {
  const { searchStore } = useMobxStores();
  const cardsRef = useRef<any>([]);

  useEffect(() => {
    if (!isServer) {
      setStorageCheckinCheckout(checkin, checkout);
    }
  }, [checkin, checkout]);

  const filteredFacilities =
    searchStore.filteredFacilities ?? searchAlternate?.result;
  return (
    <>
      {filteredFacilities?.map((item, index) => {
        return (
          <div key={index} className="mt-8">
            <Card
              configInfo={configInfo}
              item={item}
              index={index}
              cardsRef={cardsRef}
              checkin={checkin}
              checkout={checkout}
            />
          </div>
        );
      })}

      {filteredFacilities?.length === 0 && (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  );
}

export default observer(CardList);
