import React from 'react';
import Image from 'next/image';
import Cta from './Cta';
import usePrefix from '@/hooks/usePrefix';
import { CDN2 } from '@/helpers/api';
import ButtonStore from './ButtonStore';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';

export default function DownloadApp({
  landingPage = false,
  mobileFeeDiscount,
}) {
  const prefix = usePrefix();
  const { t } = useTranslation();

  return (
    <div>
      <div className="container mx-auto px-4 max-w-4xl ">
        <div
          className={`${
            landingPage ? '' : 'shadow-sm'
          } rounded-md flex flex-col px-2 py-4 mb-4 mx-2 bg-white h-full ${
            landingPage && ` !shadow-none`
          }`}
        >
          <div
            className={`flex flex-col md:flex-row-reverse items-center md:gap-8 ${
              landingPage ? 'md:mx-24' : 'md:mx-32'
            }`}
          >
            <div>
              {landingPage ? (
                <>
                  <h2 className="text-3xl md:text-6xl text-center mt-4 mb-0 font-bold">
                    {t('save_time')}
                  </h2>

                  <h2
                    className={`text-center font-bold 
              ${'text-xl md:text-3xl md:mt-2'}
              `}
                  >
                    {t('download_our_app')}
                  </h2>
                </>
              ) : (
                <p className="text-lg md:text-3xl text-center">
                  <b>Exclusive App Deals & Savings!</b>
                </p>
              )}

              <Link
                passHref
                href={`/myreservation/getapp?redir=1`}
                className="text-center hover:underline"
              >
                <Image
                  src={`${CDN2()}/staticmyapp/qrcodeDownloadAppRedir.png`}
                  width={400}
                  height={400}
                  alt="Download App QR Code"
                  className="mx-auto"
                />
                <p className="text-xs -mt-4">
                  Scan or <b>tap the QR code</b> to download the app.
                </p>
              </Link>

              {landingPage ? (
                <p
                  className={`${
                    landingPage ? `text-sm md:text-xl mb-6 mt-2` : ' my-4'
                  }`}
                >
                  {t('app_description')}
                </p>
              ) : (
                (mobileFeeDiscount ?? 0) > 0 && (
                  <>
                    <p
                      className={`${
                        landingPage
                          ? `text-sm md:text-2xl mb-6 mt-2`
                          : ' my-4 text-sm md:text-2xl text-center'
                      }`}
                    >
                      Get <b>${mobileFeeDiscount} OFF today </b>and enjoy more
                      discounts in the future!
                    </p>
                  </>
                )
              )}
            </div>
            <Image
              quality={65}
              src={CDN2() + '/staticmyapp/appDownload.webp'}
              alt="Download App"
              width={174}
              height={350}
              style={{ width: '100%', height: '100%' }}
              className="rounded-md"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
