import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const LanguageSwitcher = ({ extraClass }) => {
  const { i18n } = useTranslation();
  const [urlLang, setUrlLang] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const language = urlParams.get('language');

    if (language === 'es') {
      setUrlLang('es');
      if (i18n.language !== 'es') {
        i18n.changeLanguage('es');
      }
    } else {
      setUrlLang(null);
    }
  }, []);

  const handleChangeLanguage = (value) => {
    i18n.changeLanguage(value);
    if (urlLang) {
      return;
    }
  };

  return (
    <div
      className={`flex items-center justify-end ${extraClass} px-10 md:px-32 pt-4 gap-2`}
    >
      <span className="text-sm font-bold">Translate:</span>
      <Select
        value={i18n.language}
        onChange={handleChangeLanguage}
        className="w-28 border"
        dropdownClassName="text-sm"
      >
        <Option value="en">English</Option>
        <Option value="es">Español</Option>
      </Select>
    </div>
  );
};

export default LanguageSwitcher;
