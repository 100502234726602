import React from 'react';

export default function EnhancedText({ value }) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: value }}
      className="pb-4 title3"
      suppressHydrationWarning
    />
  );
}
