import { useState, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { isServer } from '@/utils/isServer';

function getSessionStorageValue(key, defaultValue) {
  // getting stored value
  if (isServer) {
    return defaultValue;
  }
  const saved = sessionStorage.getItem(key);
  const initial = JSON.parse(saved);
  return initial || defaultValue;
}

export const useSessionStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    return getSessionStorageValue(key, defaultValue);
  });

  useEffect(() => {
    // storing input name
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

function getLocalStorageValue(key, defaultValue) {
  // getting stored value
  if (isServer) {
    return defaultValue;
  }
  const saved = localStorage.getItem(key);
  const initial = JSON.parse(saved);
  return initial || defaultValue;
}

export const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    return getLocalStorageValue(key, defaultValue);
  });

  useEffect(() => {
    // storing input name
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

export function setStorageCheckinCheckout(checkin, checkout) {
  const vchin = dayjs(checkin);
  const vchout = dayjs(checkout);
  setTimeout(() => {
    sessionStorage.setItem(
      'checkincheckout',
      JSON.stringify({
        checkin: vchin.format('YYYY-MM-DD'),
        checkout: vchout.format('YYYY-MM-DD'),
        checkintime: vchin.format('HH:mm'),
        checkouttime: vchout.format('HH:mm'),
      })
    );
  });
}

export const useStorageCheckinCheckout = (facility) => {
  const curcheckincheckout = getSessionStorageValue('checkincheckout', null);
  if (curcheckincheckout) {
    const today = dayjs().format('YYYY-MM-DD');
    if (
      curcheckincheckout.checkin < today &&
      curcheckincheckout.checkout < today
    ) {
      setStorageCheckinCheckout(
        facility?.facilityParkingType == 0
          ? dayjs().add(1, 'day').format('YYYY-MM-DD')
          : today,
        facility?.facilityParkingType == 1
          ? dayjs().add(5, 'day').format('YYYY-MM-DD')
          : facility?.facilityParkingType == 0
          ? dayjs().add(8, 'day').format('YYYY-MM-DD')
          : dayjs().add(1, 'day').format('YYYY-MM-DD')
      );
    }
  }
  const [checkincheckout, setCheckinCheckout] = useSessionStorage(
    'checkincheckout',
    {
      checkin:
        facility?.facilityParkingType == 0
          ? dayjs().add(1, 'day').format('YYYY-MM-DD')
          : dayjs().format('YYYY-MM-DD'),
      checkout:
        facility?.facilityParkingType == 1
          ? dayjs().add(5, 'day').format('YYYY-MM-DD')
          : facility?.facilityParkingType == 0
          ? dayjs().add(8, 'day').format('YYYY-MM-DD')
          : dayjs().add(1, 'day').format('YYYY-MM-DD'),
    }
  );
  return [
    checkincheckout,
    setCheckinCheckout,
    (checkin: Dayjs, checkout: Dayjs, withHour = 0) => {
      if (!checkin.isValid() || !checkout.isValid()) {
        setCheckinCheckout({
          checkin: dayjs().format('YYYY-MM-DD'),
          checkout: dayjs().add(1, 'day').format('YYYY-MM-DD'),
        });
      } else {
        const data = {
          checkin: checkin.format('YYYY-MM-DD'),
          checkout: checkout.format('YYYY-MM-DD'),
        };
        if (withHour) {
          data['checkintime'] = checkin.format('HH:mm');
          data['checkouttime'] = checkout.format('HH:mm');
        }
        setCheckinCheckout(data);
      }
    },
  ];
};
