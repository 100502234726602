/* eslint-disable jsx-a11y/alt-text */
import { IReviews } from '@/types';
import { Button, Rate } from 'antd';
import { useTranslation } from 'react-i18next';

export interface IReviewsLanding {
  reviews: IReviews[];
  isNewLanding?: boolean;
  airport?: string;
}

export default function ReviewsLanding({
  reviews,
  isNewLanding,
  airport,
}: IReviewsLanding) {
  const { t } = useTranslation();

  return (
    <div className="pb-8 flex flex-col">
      {isNewLanding ? (
        <h3
          className={`leading-none px-4 w-full text-center py-10 font-bold title3`}
        >
          {t('real_reviews', { airport: airport })}
        </h3>
      ) : (
        <h2 className="text-3xl md:text-6xl mt-8 md:mt-16 mb-2 text-center px-4 font-bold">
          {t('your_feedback')}
        </h2>
      )}

      <div
        className={`grid grid-cols-1 xl:grid-cols-3 md:gap-x-8 gap-y-8 px-4 md:px-6 ${
          isNewLanding ? 'md:pb-10' : ' md:my-12'
        }`}
      >
        {!reviews ? (
          <>
            <div className="bg-gray-200 flex flex-col items-center rounded-md p-8">
              <p className="text-xs md:text-base text-center mt-6 mb-2">
                {t('happy_customer')}
              </p>
              <Rate
                disabled
                defaultValue={5}
                className="!text-2xl !md:text-3xl"
              />
              <p className="text-2xl md:text-3xl text-center mt-7 font-bold">
                {t('parking_was_easy')}
              </p>

              <p className="text-center mt-4 text-xs md:text-base">
                {t('parking_easy_review')}
              </p>
            </div>
            <div className="bg-gray-200 flex flex-col items-center rounded-md p-8">
              <p className="text-xs md:text-base text-center mt-6 mb-2">
                {t('happy_customer')}
              </p>
              <Rate
                disabled
                defaultValue={5}
                className="!text-2xl !md:text-3xl"
              />
              <p className="text-2xl md:text-3xl text-center mt-7 font-bold">
                {t('parking_good_rate')}
              </p>

              <p className="text-center mt-4 text-xs md:text-base">
                {t('good_rate_parking')}
              </p>
            </div>
            <div className="bg-gray-200 flex flex-col items-center rounded-md p-8">
              <p className="text-xs md:text-base text-center mt-6 mb-2">
                {t('happy_customer')}
              </p>
              <Rate
                disabled
                defaultValue={5}
                className="!text-2xl !md:text-3xl"
              />
              <p className="text-2xl md:text-3xl text-center mt-7 font-bold">
                {t('great_service')}
              </p>

              <p className="text-center mt-4 text-xs md:text-base">
                {t('great_service_review')}
              </p>
            </div>
          </>
        ) : (
          <>
            {reviews.map((review) => (
              <div
                className="bg-gray-200 flex flex-col items-center rounded-md p-8"
                key={review.review_id}
              >
                <p className="text-xl !md:text-2xl text-center mt-6 mb-2 font-bold">
                  {review.member_email}
                </p>
                <Rate
                  disabled
                  defaultValue={review.review_grade}
                  className="!text-xl !md:text-2xl"
                />
                <p className="text-center mt-4 text-sm md:text-base">
                  {review.review_comment}
                </p>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}
