/* eslint-disable @next/next/no-img-element */
import { Card } from 'antd';
import { CDN2 } from '@helpers/api';
import Image from 'next/image';
import Link from 'next/link';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const { Meta } = Card;

interface ITopAirport {
  items?: any[];
  isNewLanding?: boolean;
}

export default function PeopleAlso({ items, isNewLanding }: ITopAirport) {
  const { t } = useTranslation();

  return (
    <section
      className={`h-auto flex flex-col justify-center items-center landing-people-also  ${
        isNewLanding ? 'pb-10' : 'bg-orange-50 py-10'
      }`}
    >
      {isNewLanding ? (
        <h3
          className={`leading-none w-full text-center pb-2 pt-10 font-bold title3`}
        >
          {t('recent_blog_articles')}
        </h3>
      ) : (
        <h2 className="text-3xl md:text-6xl mt-8 mb-4 text-center px-4 font-bold">
          {t('people_also_search_for')}
        </h2>
      )}
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-6 gap-y-8 my-8">
        {items.map((item, idx) => {
          return (
            <Link href={item.url} key={idx}>
              <Card
                bordered={false}
                className={`w-96 cursor-pointer 
                  ${
                    isNewLanding
                      ? 'border border-orange-50 p-4 md:w-full max-w-2xl'
                      : 'bg-orange-50 px-4 md:w-[30rem]'
                  }`}
              >
                <Image
                  alt={item.name}
                  className="z-30 h-60 w-full rounded-md"
                  src={item.image}
                  width={340}
                  height={240}
                />
                <p
                  className={`text-center md:text-left mb-4 ${
                    !isNewLanding && 'text-lg md:text-xl'
                  }`}
                >
                  {dayjs(item.date).format('MM-DD-YYYY')}
                </p>
                <Meta
                  title={
                    isNewLanding ? (
                      <h4 className="text-xl">{item.name}</h4>
                    ) : (
                      <>{item.name}</>
                    )
                  }
                  className="flex justify-center md:justify-start"
                  description={
                    <div className="flex flex-row items-center justify-center md:justify-start">
                      <p
                        className={`text-base md:text-lg ${
                          isNewLanding && 'text-secondary'
                        }`}
                      >
                        {t('read_full_blog')}
                      </p>
                      <Image
                        src={CDN2() + '/staticmyapp/arrow-up-right.png'}
                        width={20}
                        height={20}
                        alt="icon"
                      />
                    </div>
                  }
                />
              </Card>
            </Link>
          );
        })}
      </div>
    </section>
  );
}
