/* eslint-disable react/jsx-key */
import { useTranslation } from 'react-i18next';

interface INews {
  googleNews: any[];
  isNewLanding?: boolean;
  airport?: string;
}

export default function GoogleNews({
  googleNews,
  isNewLanding,
  airport,
}: INews) {
  const { t } = useTranslation();

  if (!googleNews) return <></>;
  return (
    <section
      className={`bg-orange-50 h-auto flex flex-col justify-center items-center landing-people-also ${
        isNewLanding ? 'pb-10' : 'py-10 '
      }`}
    >
      {isNewLanding ? (
        <>
          <h3
            className={`leading-none w-full text-center pb-2 pt-10 font-bold title3`}
          >
            {t('airport_news', { airport: airport })}
          </h3>
          <p className="text-sm px-6 md:text-2xl pb-10 text-center">
            {t('airport_news_description')}
          </p>
        </>
      ) : (
        <h2 className="text-3xl md:text-6xl mt-8 mb-10 text-center px-4 font-bold">
          {t('news')}
        </h2>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-5 md:gap-x-8 gap-y-8 px-6 md:px-20 md:mt-12">
        {googleNews.map((news) => (
          <div
            key={news.link._text}
            className="bg-white flex flex-col rounded-md p-8"
          >
            <p className="text-left mb-2 text-cityList">{news.pubDate._text}</p>
            <p className="text-left mb-2 font-medium md:text-lg">
              {news.title._text}
            </p>
            <p className="font-light">{news.source._text}</p>
            <a
              target="_blank"
              href={news.link._text}
              className="text-secondary mt-2"
            >
              {t('read_more')}
            </a>
          </div>
        ))}
      </div>
    </section>
  );
}
